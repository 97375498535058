'use client';
import { builder, Builder, withChildren } from '@builder.io/react';
import Counter from 'components/Counter/Counter';
import { AccordionGeneric as Accordion } from 'components/accordion/Accordion';
import { AffiliateForm } from 'components/affiliateForm/AffiliateForm';
import { AffiliatePhone } from 'components/affiliatePhone/AffiliatePhone';
import { CookiePhone } from 'components/affiliatePhone/CookiePhone';
import { MainBanner } from 'components/banner/index';
import { BenefitCard } from 'components/benefitCard/BenefitCard';
import { ButtonModal } from 'components/buttonModal/ButtonModal';
import { ButtonGreen } from 'components/buttons/default-green';
import { ContactForm } from 'components/contactForm/ContactForm';
import { LensForm } from 'components/lensForm/LensForm';
import { PackageItemsList } from 'components/packageItemList/packageItemList';
import { PromotionBanner } from 'components/promotionBanner/PromotionBanner';
import { Ratings } from 'components/ratings/Ratings';
import { RequestQuoteWizard } from 'components/requestQuote/RequestQuoteWizard';
import { ProductBox } from 'components/shopify/product-box';



builder.init(process.env.NEXT_PUBLIC_BUILDER_API_KEY!);

Builder.register('editor.settings', {
  styleStrictMode: false,
  designTokens: {
    colors: [
      { name: 'Brand Green', value: 'var(--fp-green, #35823c)' },
      { name: 'Light Green', value: 'var(--fp-light-green, #def1df)' },
      { name: 'Light Gray', value: 'var(--fp-light-gray, #f9f9f9)' },
      { name: 'Gray', value: 'var(--fp-gray, #6e6e6e)' },
      { name: 'Dark Gray', value: 'var(--fp-dark-gray, #3e3e3e)' }
    ],
    lineHeight: [
      { name: 'Tight', value: '1' },
      { name: 'Normal', value: '1.5' },
      { name: 'Relaxed', value: '2' }
    ],
    fontWeight: [
      { name: 'Light', value: '300' },
      { name: 'Normal', value: '400' },
      { name: 'Bold', value: '700' }
    ],
    fontFamily: [{ name: 'Primary Font', value: 'var(--font-cerebri-sans)' }],
    spacing: [
      { name: 'Large', value: 'var(--space-large, 20px)' },
      { name: 'Small', value: 'var(--space-small, 10px)' },
      { name: 'Tiny', value: '5px' }
    ]
  }
});

Builder.registerComponent(Counter, {
  name: 'Counter',
  inputs: [
    {
      name: 'initialCount',
      type: 'number'
    }
  ]
});

Builder.registerComponent(Accordion, {
  name: 'Accordion',
  noWrap: true,
  inputs: [
    { name: 'header', type: 'richText', defaultValue: 'Header Content' },
    {
      name: 'accordionItems',
      type: 'list',
      subFields: [
        {
          name: 'title',
          type: 'string',
          required: true,
          defaultValue: 'Accordion Item'
        },
        {
          name: 'content',
          type: 'richText',
          required: true,
          defaultValue: 'Accordion content'
        }
      ]
    }
  ]
});

Builder.register('insertMenu', {
  name: 'Shopify Custom Components',
  items: [{ name: 'Product Box' }]
});

Builder.registerComponent(ProductBox, {
  // Overrides built-in Text component
  name: 'Product Box',
  inputs: [
    {
      name: 'builderProductHandle',
      type: 'ShopifyProductHandle',
      required: true
    },
    {
      name: 'variant',
      type: 'text',
      defaultValue: 1,
      enum: [
        { label: 'variant 1', value: 1 },
        { label: 'variant 2', value: 2 }
      ]
    }
  ]
  // Other properties...
});

Builder.registerComponent(withChildren(ButtonModal), {
  name: 'Button Modal',
  canHaveChildren: true,
  inputs: [
    { name: 'text', type: 'text', defaultValue: 'Open' },
    {
      name: 'size',
      type: 'text',
      enum: ['sm', 'md', 'lg', 'xl', '2xl', '3xl', '4xl', '5xl', '6xl'],
      defaultValue: 'sm'
    },
    {
      name: 'variant',
      type: 'text',
      enum: ['primary', 'secondary', 'link'],
      defaultValue: 'primary'
    }
  ]
});

Builder.registerComponent(RequestQuoteWizard, {
  name: 'Request Quote Wizard',
  noWrap: true,
  inputs: [
    { name: 'formId', type: 'text' },
    {
      name: 'sourceKey',
      type: 'text',
      defaultValue: '3322d116-cbee-43ef-903d-ac0b07ffbebb',
      required: true
    },
    {
      name: 'variant',
      type: 'text',
      enum: ['default', 'lifecare'],
      defaultValue: 'default'
    },
    {
      name: 'steps',
      type: 'list',
      subFields: [
        {
          name: 'stepId',
          type: 'string',
          required: true
        },
        {
          name: 'title',
          type: 'text'
        },
        {
          name: 'description',
          type: 'text'
        },
        {
          name: 'buttons',
          type: 'list',
          subFields: [
            {
              name: 'value',
              type: 'string',
              required: true
            },
            {
              name: 'text',
              type: 'richText',
              required: true
            }
          ],
          defaultValue: []
        }
      ]
    }
  ]
});

Builder.registerComponent(LensForm, {
  name: 'Lens Form',
  noWrap: true,
  inputs: [
    {
      name: 'sourceKey',
      type: 'text',
      defaultValue: '3322d116-cbee-43ef-903d-ac0b07ffbebb',
      required: true
    },
  ]
});

Builder.registerComponent(AffiliatePhone, {
  name: 'Affiliate Phone',
  noWrap: true,
  inputs: [{ name: 'defaultPhone', type: 'text' }]
});

Builder.registerComponent(CookiePhone, {
  name: 'Cookie Phone',
  noWrap: true,
});

Builder.registerComponent(Ratings, {
  name: 'Ratings',
  noWrap: true,
  inputs: []
});

Builder.registerComponent(withChildren(BenefitCard), {
  name: 'Benefit Card',
  canHaveChildren: true,
  inputs: [
    { name: 'title', type: 'text' },
    { name: 'description', type: 'richText' },
    { name: 'backgroundImage', type: 'file', allowedFileTypes: ['jpeg', 'png', 'webp'] },
    { name: 'buttonMessage', type: 'text' },
    { name: 'buttonLink', type: 'text' },
    { name: 'useDialog', type: 'boolean', defaultValue: false }
  ]
});

Builder.registerComponent(withChildren(MainBanner), {
  name: 'Builder Banner',
  inputs: [
    {
      name: 'slideInterval',
      type: 'number',
      defaultValue: 5000
    },
    {
      name: 'slide',
      type: 'boolean',
      defaultValue: true
    },
    {
      name: 'indicators',
      type: 'boolean',
      defaultValue: true
    }
  ],
  canHaveChildren: true
});

Builder.registerComponent(withChildren(ButtonGreen), {
  name: 'ButtonGreen',
  canHaveChildren: true,
  inputs: [
    { name: 'href', type: 'text', defaultValue: '#', required: true },
    { name: 'className', type: 'text', defaultValue: '' }
  ],
  defaultChildren: [
    {
      '@type': '@builder.io/sdk:Element',
      component: {
        name: 'Text',
        options: {
          text: 'Button'
        }
      }
    }
  ]
});

Builder.registerComponent(PromotionBanner, {
  name: 'PromotionBanner',
  inputs: [
    { name: 'text', type: 'text', defaultValue: '', required: true },
    { name: 'policy', type: 'richText', defaultValue: '', required: true }
  ]
});

Builder.registerComponent(ContactForm, {
  name: 'ContactForm',
  inputs: []
});

Builder.registerComponent(AffiliateForm, {
  name: 'AffiliateForm',
  inputs: []
});

Builder.registerComponent(PackageItemsList, {
  name: 'Package Items List',
  inputs: [
    {
      name: 'heading',
      type: 'string',
      defaultValue: 'INCLUDED IN THIS PACKAGE'
    },
    {
      name: 'items',
      type: 'list',
      subFields: [
        {
          name: 'quantity',
          type: 'string'
        },
        {
          name: 'title',
          type: 'string'
        },
        {
          name: 'description',
          type: 'longText'
        }
      ]
    }
  ]
});
