'use client'
import { getCookiePhoneNumber } from "app/actions/affiliate.actions";
import { useEffect, useState } from "react";

export function CookiePhone() {
    const [phone, setPhone] = useState<string>('');

    useEffect(() => {
        const fetchPhone = async () => {
            const phone = await getCookiePhoneNumber() || '833-606-4477'; 
            setPhone(phone);
        };
        
        fetchPhone();
    }, []);
  
    return <span>{phone}</span>;
}